<template>
  <div class="contenido">
    <h2>Nuestros servicios</h2>
    <div class="servicios">
      <div class="service">
        <div class="title">Evaluación de riesgos</div>
        <div class="icon">
          <svg
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 490.275 490.275"
            style="enable-background: new 0 0 490.275 490.275;"
            xml:space="preserve"
          >
            <g>
              <g>
                <path
                  d="M358.854,307.215c0.6-0.4,1.2-0.9,1.9-1.3l7-4c10.2-5.9,32-13.8,43.2-19c-7.1-4.8-14.6-5-22.3-1.8
			c-9.6,3.9-31.1,11.2-40.6,16.4C352.154,300.215,355.754,303.415,358.854,307.215z"
                />
                <path
                  d="M472.054,306.815c-9.3-0.7-28.7,10.2-34,13c-18.9,9.9-45.1,25.3-62.4,37.7c-1.2,0.8-2.8,2-4,2.8
			c-7.8,5.2-15.9,8.6-25.1,10.7c-50.1,11.1-123.2,12.3-123.2,12.3l-4.2,0.2c-3.2,0.2-6.1-2.2-6.5-5.4c-0.5-3.6,2-6.8,5.6-7.2
			l108.6-10.7c11.9-1.3,21.5-13,20.2-26c-1.3-13.1-12.9-22.6-24.9-21.3l-96.6-1.2c-6.6-0.1-17.1-1.8-23.4-3.7
			c-62.3-18.9-97.4-1.2-116.1,7.1c-4.1-7.2-12.1-11.8-20.9-11.2l-41.7,2.9c-13.9,1-24.3,13-23.4,26.8l6.7,96
			c1,13.9,13,24.3,26.8,23.4l41.7-2.9c8.8-0.6,16-6.3,19.1-13.9l183,8.2c20.4,2.2,39.5-0.3,57.5-10.1l5.2-2.8l138.3-84
			c6.8-4.1,11.6-11.3,11.9-19.2C490.654,318.515,485.254,307.815,472.054,306.815z M48.954,430.115c-7.8,0-14.2-6.4-14.2-14.2
			c0-7.8,6.4-14.2,14.2-14.2s14.2,6.4,14.2,14.2S56.754,430.115,48.954,430.115z"
                />
                <path
                  d="M235.254,124.115L235.254,124.115l-49.4,0.1c-9.2,0.3-16.5,7.7-16.5,17v93.8c0,9.4,7.6,17,17,17h131c9.4,0,17-7.6,17-17
			v-93.9c0-9.4-7.6-17-17-17h-0.5v-20.7c0-36.1-29.5-66.8-65.6-66.2c-35.1,0.6-63.4,29.3-63.4,64.5v2c0,3,2.4,5.4,5.4,5.4h17.4
			c3,0,5.4-2.4,5.4-5.4v-0.6c0-19.4,14.6-36.4,34-37.7c21.1-1.3,38.6,15.4,38.6,36.2v22.4h-53.4V124.115z M262.054,192.315
			c-1.3,0.9-1.7,1.9-1.7,3.4c0,6.9,0,13.8,0,20.7l0,0c0.2,2.9-1.3,5.7-3.9,7c-6,3.1-12-1.2-12-6.9l0,0c0-6.9,0-13.8,0-20.7
			c0-1.4-0.3-2.3-1.5-3.3c-6.3-4.6-8.3-12.5-5.2-19.4c3-6.7,10.4-10.7,17.4-9.3c7.8,1.6,13.2,7.8,13.3,15.6
			C268.554,184.715,266.354,189.115,262.054,192.315z"
                />
                <path
                  d="M373.554,336.815c14.1-10.3,43-26.5,59.4-35.1c4.9-2.6,10.1-5.1,15.7-7.2c-7.1-4.9-14.8-5.2-22.6-2
			c-15.5,6.4-44.2,20.7-58.8,29c-0.1,0.2-0.2,0.4-0.2,0.6c1,3.1,1.8,6.4,2.1,9.8c0.3,2.7,0.3,5.4,0.1,8
			C370.654,338.915,372.154,337.815,373.554,336.815z"
                />
              </g>
            </g>
          </svg>
        </div>
        <div class="description">
          <p>Solicita un reporte para descubrir los riesgos clave en tu organización, sitio web, email, red y marca.</p>
          <p>Información en la que puedes actual al instante</p>
          <p>Miles de factores de riesgo analizados y catalogados por nivel de severidad</p>
        </div>
      </div>
      <div class="service">
        <div class="title">Prevención y Protección de filtración de datos</div>
        <div class="icon">
          <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 487 487" style="enable-background:new 0 0 487 487;" xml:space="preserve">
          <g>
            <g>
              <path d="M79.8,155.1v178.1c0,20.4,16.6,37,37,37h61.3v-22.9c0-1.9,0.1-3.7,0.4-5.6h-61.6c-4.7,0-8.5-3.8-8.5-8.5V221.3h350.1
                v111.9c0,4.7-3.8,8.5-8.5,8.5h-61.6c0.2,1.8,0.3,3.7,0.3,5.6v22.9H450c20.4,0,37-16.6,37-37V155.1c0-20.4-16.6-37-37-37H116.8
                C96.3,118.1,79.8,134.6,79.8,155.1z M458.5,155.1V174H108.3v-18.9c0-4.7,3.8-8.5,8.5-8.5H450C454.7,146.6,458.5,150.4,458.5,155.1
                z"/>
              <path d="M37,291.8h18.4v-28.5H37c-4.7,0-8.5-3.8-8.5-8.5V76.6c0-4.7,3.8-8.5,8.5-8.5h333.2c4.7,0,8.5,3.8,8.5,8.5v17.1h28.5V76.6
                c0-20.4-16.6-37-37-37H37c-20.4,0-37,16.6-37,37v178.1C0,275.2,16.5,291.8,37,291.8z"/>
              <path d="M251.1,331.9h-25.5h-1.4c-8.5,0-15.4,6.9-15.4,15.4V432c0,8.5,6.9,15.4,15.4,15.4h118.3c8.5,0,15.4-6.9,15.4-15.4v-84.7
                c0-8.5-6.9-15.4-15.4-15.4h-0.4v-18.5c0-30.3-22.4-56.8-52.6-59.7c-32.6-3.1-60.5,21-63.6,52.2c-0.3,3.1,2.1,5.7,5.2,5.7h15.2
                c2.6,0,4.7-1.9,5.1-4.5c2.4-17.3,18.3-30.4,36.8-28c16.5,2.1,28.4,17,28.4,33.6v19.1h-65.5V331.9z M292.5,393.4
                c-1.2,0.9-1.5,1.7-1.5,3.1c0,6.2,0,12.4,0,18.6l0,0c0.1,2.6-1.2,5.1-3.5,6.3c-5.4,2.8-10.9-1.1-10.9-6.3l0,0c0-6.2,0-12.5,0-18.7
                c0-1.3-0.3-2.1-1.4-2.9c-5.6-4.2-7.5-11.2-4.7-17.5c2.7-6.1,9.4-9.6,15.7-8.4c7,1.4,11.9,7.1,12,14.1
                C298.4,386.6,296.4,390.6,292.5,393.4z"/>
            </g>
          </g>

          </svg>

        </div>
        <div class="descripcion">
          <p>Protege la información personal de que sea expuesta cuando sufras un ataque</p>
          <p>Nuestro servicio te ayuda a la complir con la protección  de regulaciónes como GDPR, CCPA, HIPAA, GLBA, NYDFS</p>
        </div>
      </div>
      <div class="service">
        <div class="title">Pruebas de Vulnerabilidad</div>
        <div class="icon">
          <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 490.231 490.231" style="enable-background:new 0 0 490.231 490.231;" xml:space="preserve">
          <g>
            <g>
              <path d="M430.85,86.531c-9.6-14.4-22.1-27.1-37-37l8.5-20.4c3-7.3-0.4-15.7-7.7-18.7l-22.3-9.3c-7.3-3-15.7,0.4-18.7,7.7
                l-8.5,20.4c-17.6-3.5-35.4-3.4-52.4,0l-8.5-20.4c-3-7.3-11.4-10.8-18.7-7.7l-22.2,9.2c-7.3,3-10.8,11.4-7.7,18.7l8.5,20.4
                c-14.4,9.6-27.1,22.1-37,37l-20.4-8.5c-7.3-3-15.7,0.4-18.7,7.7l-9.2,22.2c-3,7.3,0.4,15.7,7.7,18.7l20.4,8.5
                c-3.5,17.6-3.4,35.4,0,52.4l-20.4,8.4c-7.3,3-10.8,11.4-7.7,18.7l9.2,22.2c3,7.3,11.4,10.8,18.7,7.7l20.4-8.3
                c9.6,14.4,22.1,27.1,37,37l-8.5,20.4c-3,7.3,0.4,15.7,7.7,18.7l22.2,9.2c7.3,3,15.7-0.4,18.7-7.7l8.5-20.4
                c17.6,3.5,35.4,3.4,52.4,0l8.4,20.4c3,7.3,11.4,10.8,18.7,7.7l22.2-9.2c7.3-3,10.8-11.4,7.7-18.7l-8.4-20.4
                c14.4-9.6,27.1-22.1,37-37l20.4,8.5c7.3,3,15.7-0.4,18.7-7.7l9.2-22.2c3-7.3-0.4-15.7-7.7-18.7l-20.4-8.5
                c3.5-17.6,3.4-35.4,0-52.4l20.4-8.4c7.3-3,10.8-11.4,7.7-18.7l-9.2-22.2c-3-7.3-11.4-10.8-18.7-7.7L430.85,86.531z
                M409.15,198.631c-20.6,49.8-77.7,73.4-127.5,52.8s-73.4-77.7-52.8-127.5s77.7-73.4,127.5-52.8
                C406.15,91.831,429.75,148.831,409.15,198.631z"/>
              <path d="M231.35,401.631c-3.6,0-6.5,2.9-6.5,6.4l-0.1,10.9c0,3.6,2.9,6.5,6.4,6.5l10,0.1c1.5,7.7,4.5,15.2,8.9,22l-7.1,7
                c-2.5,2.5-2.6,6.6,0,9.2l7.7,7.7c2.5,2.5,6.6,2.6,9.2,0l7.1-7c6.7,4.6,14.2,7.6,21.9,9.2l-0.1,10c0,3.6,2.9,6.5,6.4,6.5l10.9,0.1
                c3.6,0,6.5-2.9,6.5-6.4l0.1-10c7.7-1.5,15.2-4.5,22-9l7,7.1c2.5,2.5,6.6,2.6,9.2,0l7.7-7.7c2.5-2.5,2.6-6.6,0-9.2l-7-7.1
                c4.6-6.7,7.6-14.2,9.2-21.9l10,0.1c3.6,0,6.5-2.9,6.5-6.4l0.1-10.9c0-3.6-2.9-6.5-6.4-6.5l-10-0.1c-1.5-7.7-4.5-15.2-8.9-22l7.1-7
                c2.5-2.5,2.6-6.6,0-9.2l-7.7-7.7c-2.5-2.5-6.6-2.6-9.2,0l-7.1,7c-6.7-4.6-14.2-7.6-21.9-9.2l0.1-10c0-3.6-2.9-6.5-6.4-6.5
                l-10.9-0.1c-3.6,0-6.5,2.9-6.5,6.4l-0.1,10c-7.7,1.5-15.2,4.5-22,9l-7-7.1c-2.5-2.5-6.6-2.6-9.2,0l-7.7,7.7
                c-2.5,2.5-2.6,6.6,0,9.2l7,7.1c-4.6,6.7-7.6,14.2-9.2,21.9L231.35,401.631z M280.55,393.331c11.4-11.3,29.8-11.2,41,0.2
                s11.2,29.8-0.2,41c-11.4,11.3-29.8,11.2-41-0.2C269.15,422.931,269.25,404.631,280.55,393.331z"/>
              <path d="M19.35,377.631l14.4,0.1c2.1,11.1,6.4,21.8,12.9,31.6l-10.2,10.1c-3.7,3.6-3.7,9.5-0.1,13.2l11,11.1
                c3.6,3.7,9.5,3.7,13.2,0.1l10.2-10.1c9.7,6.6,20.4,11,31.4,13.2l-0.1,14.4c0,5.1,4.1,9.3,9.3,9.4l15.7,0.1c5.1,0,9.3-4.1,9.4-9.3
                l0.1-14.4c11.1-2.1,21.8-6.4,31.6-12.9l10.1,10.2c3.6,3.7,9.5,3.7,13.2,0.1l11.1-11c3.7-3.6,3.7-9.5,0.1-13.2l-10.1-10.2
                c6.6-9.7,11-20.4,13.2-31.4l14.4,0.1c5.1,0,9.3-4.1,9.4-9.3l0.1-15.7c0-5.1-4.1-9.3-9.3-9.4l-14.4-0.1
                c-2.1-11.1-6.4-21.8-12.9-31.6l10.2-10.1c3.7-3.6,3.7-9.5,0.1-13.2l-11-11.1c-3.6-3.7-9.5-3.7-13.2-0.1l-10.2,10.1
                c-9.7-6.6-20.4-11-31.4-13.2l0.1-14.4c0-5.1-4.1-9.3-9.3-9.4l-15.7-0.1c-5.1,0-9.3,4.1-9.4,9.3l-0.1,14.4
                c-11.1,2.1-21.8,6.4-31.6,12.9l-10.1-10.2c-3.6-3.7-9.5-3.7-13.2-0.1l-11.1,11c-3.7,3.6-3.7,9.5-0.1,13.2l10.1,10.2
                c-6.6,9.7-11,20.4-13.2,31.4l-14.4-0.1c-5.1,0-9.3,4.1-9.4,9.3l-0.1,15.7C10.05,373.331,14.15,377.531,19.35,377.631z
                M90.45,331.331c16.4-16.2,42.8-16.1,59,0.3s16.1,42.8-0.3,59s-42.8,16.1-59-0.3C73.85,373.931,74.05,347.531,90.45,331.331z"/>
              <path d="M360.55,142.531h-0.3v-14.2c0-22.5-18.3-40.9-40.9-40.9c-22.5,0-40.9,18.3-40.9,40.9v14.2h-1c-6,0-10.8,4.8-10.8,10.8
                v59.5c0,6,4.8,10.8,10.8,10.8h83c6,0,10.8-4.8,10.8-10.8v-59.5C371.35,147.331,366.45,142.531,360.55,142.531z M325.45,185.731
                c-0.8,0.6-1.1,1.2-1,2.2c0,4.4,0,8.7,0,13.1l0,0c0.1,1.8-0.8,3.6-2.5,4.4c-3.8,2-7.6-0.8-7.6-4.4l0,0c0-4.4,0-8.8,0-13.1
                c0-0.9-0.2-1.5-1-2.1c-4-2.9-5.3-7.9-3.3-12.3c1.9-4.3,6.6-6.8,11.1-5.9c4.9,1,8.4,5,8.4,9.9
                C329.55,180.931,328.15,183.731,325.45,185.731z M342.35,142.531h-46v-14.2c0-12.7,10.3-23,23-23s23,10.3,23,23V142.531z"/>
            </g>
          </g>
          </svg>
        </div>
        <div class="descripcion">
          <p>Hacemos pruebas a tu infraestructura, servidores, nube, aplicaciones, configuraciones de seguridad y más</p>
          <p>Trabajamos de la mano con un equipo de expertos certificados: Offensive Security Certified Professionals (OSCP) para asegurar que estes bien protegido</p>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  methods: {
    algo() {
      console.log("in");
    },
  },
};
</script>

<style scoped lang="scss">
.contenido{
  margin: 0 auto;
  padding:0;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  h2 {
    text-align: center;
    border-bottom: 1px solid chartreuse;
    font-size:1.5rem;
    @media screen and (min-width:760px) {
      font-size:2.1rem;
      line-height:2.5rem;
    }
  }
}

.servicios {
  width: 100%;
  padding-bottom: 80px;
  margin-bottom: 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  svg {
    width:90px;
    fill: #fff;
  }
  svg:hover {
    fill: chartreuse;
  }

  @media screen and (min-width:760px) {
    flex-direction: row;
  }
}

.service{
  border:1px solid #dadada;
  border-radius: 5px;
  margin:10px auto;
  padding:5px;
  width:280px;
  text-align: center;
  background-color: rgba(10,10,10,0.5);
  .title{
    font-size:1.2rem;
    margin: 20px auto;
    @media screen and (min-width:760px) {
      font-size:1.4rem;
    }
  }
  p{
    text-align: left;
    padding:0 7px;
    margin: 12px 0;
  }
  @media screen and (min-width:760px) {
    margin: 10px 25px;
    min-height: 380px;
  }
}
</style>
