<template>
  <div class="stats">
    <div class="dato">
      <p class="data">{{ data }}</p>
      <div class="line"></div>
      <div class="author">{{ author }}</div>
    </div>
    <div class="info">
      <div class="intro">
        <h2>¿Qué tan segura es tu organización?</h2>
        <div class="row">
          Déjanos ayudarte con conocimientos y acciones que puedes tomar de
          inmediato. Te ayudamos a responder las pregúntas más comunes
        </div>
        <ul>
          <li>¿Cuánto me costaría una filtración de datos de mis clientes?</li>
          <li>¿Qué tan robustas son mis defensas actuales?</li>
          <li>¿Qué tan amplia es mi superficie de ataque?</li>
          <li>¿Qué puedo hacer hoy para reducir mi riesto?</li>
        </ul>
      </div>
      <div class="cta">
        <h2>
          Nuestros servicios de prevención de ataques fortalezen tu TI contra
          ataques
        </h2>
        <div class="btn" @click="gotoContacto">
          Solicita una evaluación de riesgos
        </div>
        <div class="btn" @click="gotoServicios">
          Conoce todos nuestros servicios
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      datos: [
        {
          data: "95% de las violaciones de seguridad se deben a error humano",
          author: "Cybint",
        },
        {
          data:
            "El costo promedio de una violación de datos es de 3.2 millones de dólares",
          author: "IBM",
        },
        {
          data: "El pago promedio por randsomeware creció 33% en el 2020",
          author: "Fintech News",
        },
        {
          data:
            "En promedio cada empleado tiene acceso a 11 millones de archivos",
          author: "Varonis",
        },
        {
          data:
            "88% de las empresas gastarón más de 1 millón para prepararse para GDPR",
          author: "IT Governance",
        },
      ],
      data: "",
      author: "",
      sliderInterval: null,
      counter: 0,
    };
  },
  mounted() {
    this.data = this.datos[0].data;
    this.author = this.datos[0].author;
    this.init();
  },
  beforeDestroy() {
    clearInterval(this.sliderInterval);
  },
  methods: {
    init() {
      this.sliderInterval = setInterval(() => {
        this.counter > 3 ? (this.counter = 0) : this.counter++;
        this.data = this.datos[this.counter].data;
        this.author = this.datos[this.counter].author;
      }, 4000);
    },
    gotoContacto() {
      this.$router.push("/contacto");
    },
    gotoServicios() {
      this.$router.push("/servicios");
    },
  },
};
</script>

<style scoped lang="scss">
.stats {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: #dadada;
  h2 {
    padding: 0 1em;
    text-align: center;
    color: #fff;
  }
}

.cta {
  max-width: 500px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .btn {
    margin: 25px auto;
    cursor: pointer;
    font-family: Helvetica, sans-serif;
    display: inline-block;
    z-index: 100;
    transform: translate(0%, -10%);
    color: white;
    text-decoration: none;
    padding: 0.75rem 1rem;
    border-radius: 80px;
    background: linear-gradient(270deg, #1e80dc, #c61590);
    //From Instagram...thanks
    transition: transform 250ms ease;
    &:hover,
    &:focus,
    &:active {
      transform: translate(0%, 0%);
    }
  }
}

.dato {
  margin: 20px auto;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.intro {
  max-width: 500px;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.info {
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  @media screen and (min-width: 760px) {
    flex-direction: row;
  }
}

.data {
  padding: 5px 35px;
  font-size: 1.8rem;
  letter-spacing: 2px;
}

.line {
  margin: 5px auto;
  width: 80%;
  border: 1px solid rgb(191, 194, 3);
}

.author {
  padding: 5px 20px;
  align-self: center;
  text-align: left;
  font-size: 1.5rem;
  color: rgb(18, 156, 13);
}
</style>
